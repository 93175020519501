export const section2 = {
    packages2: [{
        title:'RDB',
        mainList: [
            'Plik do pobrania 1',
            'Plik do pobrania 2',
            'Plik do pobrania 3',
        ],
    },
    
],
    introTxt: 'Wskaż markę aby pobrać materiały:',
    contact: 'Kontakt Marketing:',
    tel: '796 503 397',
}
