import React from 'react'
import logoRDB from '../../assets/RDB Group.svg'
import logoReklama from '../../assets/reklama-dla-biznesu-logo.svg'
import logoDlaMediow from '../../assets/RDB Socials_logo.svg'
import logoRDBMedia from '../../assets/media_logo.svg'
import logoECEIB from '../../assets/ECEIB_Logo.svg'
import logoKurs from '../../assets/KursNaDrona_Logo.svg'
import logoZagraw from '../../assets/Zagrawerowani_logo.svg'
import logoZadronow from '../../assets/Zadronowani_logo.svg'
import logoWaterman from '../../assets/Waterman_logo.svg'
import RDB_pdf from '../../assets/Media_PDF/Logotyp_RDB.pdf'
import ECEiB_pdf from '../../assets/Media_PDF/ECEiB.pdf'
import Kurs_pdf from '../../assets/Media_PDF/Kurs_na_drona.pdf'
import RDB_media_pdf from '../../assets/Media_PDF/RDB_media.pdf'
import RDB_Socials_pdf from '../../assets/Media_PDF/RDB_Socials.pdf'
import RDB_reklama_pdf from '../../assets/Media_PDF/Reklama_Dla_Biznesu.pdf'
import Zadronowani_pdf from '../../assets/Media_PDF/Zadronowani.pdf'
import Zagrawerowani_pdf from '../../assets/Media_PDF/Zagrawerowani.pdf'
import Waterman_pdf from '../../assets/Media_PDF/Waterman.pdf'
import { colors, margins, fontSizes, breakpoint } from '../../cms/style'
// import stringEnter from '../../utils/stringEnter.util'
import { section2 } from '../../cms/data/dla-mediow/data'
import parse from "html-react-parser"

export default function S2() {
  const logoData = [
    { logo: logoRDB, name: 'RDB',        mainList: [
      <a css={{textDecoration:'none'}} href={RDB_pdf}>Pobierz logotyp</a>,
  ], },
    { logo: logoReklama, name: 'logoReklama',        mainList: [
      <a css={{textDecoration:'none'}} href={RDB_reklama_pdf}>Pobierz logotyp</a>,
  ], },
    { logo: logoDlaMediow, name: 'logoDlaMediów',        mainList: [
      <a css={{textDecoration:'none'}} href={RDB_Socials_pdf}>Pobierz logotyp</a>,
  ], },
    { logo: logoRDBMedia, name: 'logoRDB_Media',        mainList: [
      <a css={{textDecoration:'none'}} href={RDB_media_pdf}>Pobierz logotyp</a>,
  ], },
  { logo: logoECEIB, name: 'logoRDB_Eceib',        mainList: [
    <a css={{textDecoration:'none'}} href={ECEiB_pdf}>Pobierz logotyp</a>,
], },
{ logo: logoKurs , name: 'logoRDB_Kurs',        mainList: [
  <a css={{textDecoration:'none'}} href={Kurs_pdf}>Pobierz logotyp</a>,
], },
{ logo: logoZagraw, name: 'logoRDB_Zagrawerowani',        mainList: [
  <a css={{textDecoration:'none'}} href={Zadronowani_pdf}>Pobierz logotyp</a>,
], },
{ logo: logoZadronow, name: 'logoRDB_Zadronowani',        mainList: [
  <a css={{textDecoration:'none'}} href={Zagrawerowani_pdf}>Pobierz logotyp</a>,
], },
{ logo: logoWaterman, name: 'logoRDB_Waterman',        mainList: [
  <a css={{textDecoration:'none'}} href={Waterman_pdf}>Pobierz logotyp</a>,
], },
  ]

  return (
    section2.packages2.map(item => {
   
      const LogoItem = ({ data }) => (
        <div css={styles.box}>
        <div css={styles.ImageBox}>
        <img src={data.logo} alt=''  />
        <div>
          {data.mainList.map(el=><ul>{el}</ul>)}
        </div>
          </div>
          </div>
      )
      return(
    <section css={styles.mainSection}>
      <div css={styles.info}>
      <h1 css={{textAlign:'center',}}>{section2.contact}</h1>
        <p><strong><a css={{textDecoration:'none',color: '#00000080',}} href="tel:796 503 397">{section2.tel}</a></strong></p>
        <p css={{textAlign:'center', paddingBottom:50}}>{section2.introTxt}</p>
        <div css={styles.container}>
        {logoData.map((el, index) => <LogoItem key={index} data={el} />)}
        </div>
        </div>
        <p css={{fontStyle:'italic', fontSize:'12px',marginTop:50}}>Wszystkie materiały są objęte ochroną praw autorskich i przed ich wykorzystaniem należy skontaktować się z naszym działem marketingu w celu uzyskania zgody na wykorzystywanie oraz powielanie znaków graficznych lub kreacji.</p>

    </section>
    )})
  )
}


const styles = {
  mainSection: {
    padding: margins.sectionsX.xl,

    [breakpoint.lg]: {
      padding: margins.sectionsX.lg,
    },
    [breakpoint.md]: {
      padding: margins.sectionsX.md,
    },
    [breakpoint.sm]: {
      padding: margins.sectionsX.sm,
    },
    [breakpoint.xs]: {
      padding: margins.sectionsX.xs,
    },
  },
  container:{
    display:'flex',
    flexWrap: 'wrap',

        },
        box:{
    flexBasis:'20%',
    marginTop:20,
    '@media (max-width: 768px)':{

  flexBasis:'33%'
    },
    '@media (max-width: 447px)':{
      marginLeft:'33%',
      marginRight:'33%',
      marginTop:50,
    },
        },
  ImageBox:
  {
    width: 100,
    height: 100,
    margin:'0 auto',
    marginTop:'30px',
    justifyContent:'center',

    'img':{
      maxWidth:100,
      maxHeight:100,
      width:'100%',
      height:'100%',
    },
    ':hover':{
      cursor:'pointer',
      marginBottom:'250px',
      padding:10,
      'div': {
        width: 200,
        height:250,
        padding:10,
        visibility:'visible',
        opacity:1,
        borderRadius: 15,
        marginLeft:'-65%',
      },
      'img':{
        maxWidth:200,
        maxHeight:200,
        wdith:'80%',
        height:'80%',
      }
    },
    'div':{
 position: 'relative',
 bottom: 0,
 right: 0,
 background: '#DB0000',
 color: 'white',
 opacity: 0,
 visibility: 'hidden',
 webkittransition: 'visibility 0s, opacity 0.5s linear',
 transition: 'visibility 0s, opacity 0.5s linear',
 textAlign: 'center',
 ':hover':{
  cursor:'default',
 },
 'ul':{
  color:'white',
  marginBottom:20,
  ':hover':{
color:'black',
  },
  'a':{
color:'white',
':hover':{
color:'white'
},
  },
  'a:visited':{
color:'white'
  },
   },
    },
  },
  info: {
    textAlign:'center',
    p: {
      fontSize: "1.1rem",
    },
    showreel: {
      height: "30rem",
      border: "1px solid #000",
      margin: "3rem 0",
      [breakpoint.sm]: {
        width: "100%",
      },
    },
    [breakpoint.lg]: {
      p: {
        fontSize: fontSizes.fz4.lg,
      },
    },
    [breakpoint.md]: {
      width: "100%",
      p: {
        fontSize: fontSizes.fz4.md,
      },
    },
    [breakpoint.sm]: {
      width: "100%",

      p: {
        fontSize: fontSizes.fz4.sm,
      },
    },
    [breakpoint.xs]: {
      p: {
        fontSize: fontSizes.fz4.sm,
      },
    },
  },
  txt: {
    // padding: '15px',
    // '@media(min-width: 768px)': {
    //   margin: '40px 150px',
    //   padding: '0',
    // },
    // 'p': {
    //   marginBottom: '20px',
    // }
    'a': {
      textDecoration: 'none',
      color: '#00000080',
    },
  },
}
